














































import { Vue, Component } from "vue-property-decorator";
import Quill from "quill";
import { VueEditor } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";
import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import { ActivityGroup } from "@/types";

Quill.register("modules/htmlEditButton", htmlEditButton);

@Component({
  metaInfo() {
    return {
      title: "Oluştur / Faaliyet Grubu / Admin"
    };
  },
  components: {
    VueEditor
  }
})
export default class AdminActivityGroupCreateView extends Vue {
  activityGroup = {} as ActivityGroup;

  form = {
    title: "",
    description: null as string | null
  };

  submitDisabled = false;
  
  editorOptions = {
    modules: {
      htmlEditButton: {
        syntax: true
      }
    }
  };

  get requiredRule(): unknown[] {
    return [(v: unknown) => !!v || "Zorunlu alan"];
  }

  resetForm() {
    const form = this.$refs.form as HTMLFormElement;
    form.reset();

    this.form.description = "";
    this.resetValidation();
  }

  resetValidation() {
    const form = this.$refs.form as HTMLFormElement;
    form.resetValidation();
  }

  async update() {
    const form = this.$refs.form as HTMLFormElement;

    if (!form.validate()) {
      return;
    }

    this.submitDisabled = true;

    try {
      const res = await axios.post(`/api/activitygroup/update/${this.activityGroup.id}`,
        {
          Title: this.form.title,
          Description: this.form.description
        },
        {
          headers: this.$store.getters["auth/requestAuthHeader"]
        });

        this.$notify({ type: "success", text: "Faaliyet grubu başarıyla güncellendi." });
    } catch (e: unknown | AxiosError) {
      if (axios.isAxiosError(e)) {
        this.$notify({ type: "error", text: e.response?.data.message});
      } else {
        this.$notify({ type: "error", text: "Beklenmedik bir hata nedeniyle kullanıcı güncellenemedi." });
      }
    } finally {
      this.submitDisabled = false;
    }
  }

  async getActivityGroup() {
    try {
      const id = this.$route.params.id;
      const authHeader = this.$store.getters["auth/requestAuthHeader"];

      // Get activity group
      var res = await axios.get(`/api/activitygroup/${id}`, {
        headers: authHeader
      });

      this.activityGroup = res.data;
      this.form = res.data;
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Faaliyet grubu alınamadı.`
      });

      throw e;
    }
  }

  async mounted() {
    await this.getActivityGroup();
  }
}
